import React from "react";
import Index from "../views/EnterEvent";
import Layout from "../components/Layout/Layout";


const EnterEventPage = () => {

  return (
    <div className="bg-bgBlack">
      <Layout>
        <Index />
      </Layout>
    </div>
  );
};

export default EnterEventPage;
