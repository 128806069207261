import React, { useEffect, useContext, useState } from "react"
import { AppContext } from "../../components/Layout/context";
import { useStaticQuery, graphql } from "gatsby"
import TalkingImage from "./../../images/Group 37.png"
import { navigate } from "gatsby"
import SEO from "../../components/SEO"
const isBrowser = typeof window !== "undefined"

function EnterEvent() {
  const { lan, setLang } = useContext(AppContext)
  useEffect(() => {
    if (isBrowser && window.analytics) {
      window.analytics.track("EnterEventPageSegment")
    }

    const languagekey = localStorage.getItem("lankey");
    if (languagekey === null) {
      localStorage.setItem("lankey", "en");
      setLang("en");
    }
  }, []);

	const [communityID, setCommunityID] = useState('')
  const [isWrongCode, setIsWrongCode] = useState(false)


	function handleChange(e) {
    setCommunityID(e.target.value)
    setIsWrongCode(false)
  }


	function handleEnter(e) {
    e.preventDefault()
    if(window.gtag){
      window.gtag("event", "enter_code", {
        label: "window.gtag",
        value: communityID,
      })
    }

    if (uniqeLobbySlugs.includes(communityID.toLowerCase())) {
      navigate(`/c/${communityID.toLowerCase()}`)
    } else {
      setIsWrongCode(true)
    }
	}

  const data = useStaticQuery(graphql`
    query {
      allStrapiLobby {
        edges {
          node {
            slug
          }
        }
      }
    }
  `)
  const lobbies = data.allStrapiLobby?.edges
  const uniqeLobbySlugs = lobbies
    .map((item) => item.node.slug.toLowerCase().trim())
    .filter((value, index, self) => self.indexOf(value) === index)


  return (
    <div className="enterEvent">
      <SEO
        title={`Happen Space | Enter Event`}
        description={`The online event platform that makes conversations fun. Talks, networking, parties, team bonding, community chat. The new home for your community.`}
      />
      <div className="main-container  lg:pb-[140px] pb-20 lg:pt-[60px] md:pt-20 pt-8 md:mb-[100px]">
        <div className="grid md:grid-cols-2 gap-3  grid-cols-1 place-items-center pt-[60px]">
          <div className="md:order-1 order-2">
            <div className="serviceFourBg">
              <div className="flex  justify-center md:pt-0 pt-7 ">
                <img src={TalkingImage} alt="" />
              </div>
            </div>
          </div>
          <div className="md:order-2 order-1">
            <div className="max-w-[475px] md:text-left text-center">
              <h4 className="font-medium md:text-[46px] text-4xl leading-[40px] text-white pb-[50px] pt-[30px]">
                Enter live event!
              </h4>
              <p className="text-primary text-base font-medium pb-[30px]">
                The online event platform that makes conversations fun. Talks,
                networking, parties, team bonding, community chat. The new home
                for your community.
              </p>
              <div className="md:max-w-[474px] max-w-full mx-auto">
                <form
                  onSubmit={handleEnter}
                  className="flex md:flex-nowrap flex-wrap justify-center"
                >
                  <div className="w-full max-w-full">
                    <input
                      type="text"
                      id="communityID"
                      name="communityID"
                      data-required="true"
                      required
                      value={communityID}
                      onChange={handleChange}
                      placeholder="Enter a code"
                      className="focus:outline-none w-full pb-[18px] pt-3 text-white bg-bgBlack border placeholder-[#7D7880] border-primary rounded-l-[7px]   md:rounded-r-none rounded-r-[7px] pl-4 placeholder:text-base placeholder:text-primary placeholder:font-medium "
                    />
                    <input type="hidden" name="locale" value="en" />
                    <input type="hidden" name="html_type" value="simple" />
                  </div>
                  <button
                    id="enterEventBtn"
                    className="sm:max-w-auto max-w-full md:w-auto w-full text-center justify-center md:px-[40px] px-8 md:mt-0 mt-4 md:py-2 py-5 flex items-center rounded-r-[7px] md:rounded-l-none rounded-l-[7px] text-sm font-semibold text-white  header-btn capitalize truncate"
                    type="submit"
                  >
                    Enter event
                  </button>
                </form>
                <p className="pt-5 text-[#fc8463]" hidden={!isWrongCode}>
                  You entered the wrong code. Please recheck and try again.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="text-center pt-[70px] text-primary text-base font-medium pb-[30px]">
            Interested in creating your own events on happen?{" "}
            <a href="/#Signup" className="text-white underline">
              Signup
            </a>{" "}
            for early access to our beta
          </p>
        </div>
      </div>
    </div>
  )
}

export default EnterEvent;
